<template>
  <div class="card">
    <h1 class="card__title">Espace Perso</h1>
    <p class="card__subtitle">Voilà donc qui je suis...</p>
    <p>{{currentUser.displayName}}</p>
    <img :src="currentUser.photoURL"/>
    <div class="form-row">
      <button @click="logout" class="button">
        Déconnexion
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getAuth, signOut} from "firebase/auth";
export default {
  name: 'Profile',
  computed: {
    ...mapGetters(["currentUser"])
  },
  methods: {
    logout: function () {
      const auth = getAuth();
      signOut(auth).then(() => {
        this.$router.push('/')
      }).catch((error) => {
        console.log(error)
      });
    }
  },
}
</script>

<style scoped>
</style>>